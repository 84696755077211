
.content {
  margin: 34px 64px 0px 64px;
}

  .titleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;
  }

    .milestoneTitle {
      font-size: 26px;
      font-weight: 500;
      color: #000000;
      margin: 0px;
    }

  .milestoneContainer {
    margin-bottom: 31px;
  }

  .emptyBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 161px;
    border-radius: 8px;
    border: dashed 2px #979797;
    padding-top: 46px;
  }

    .imageContainer {
      width: 13px;
      height: 13px;
    }

    .emptyText {
      margin-top: 14px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }

    .emptyDesc {
      margin-top: 7px;
      font-size: 12px;
      line-height: 1.5;
      color: #d2d3d2;
    }
