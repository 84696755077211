.root {
  width: 100%;
  padding: 14px;
  font-size: 14px;
  border: 1px solid #dde3e8;
  color: #262626;
  border-radius: 3px;
}
.root:invalid {
  background-color: #ffd6d6;
}
.root:disabled {
  background: rgb(235, 235, 228);
}
.root::placeholder {
  color: #bbc7d0;
}
.menu {}
.root.menu {
  padding: 0;
  font-size: 12px;
  border: 0;
  border-bottom: 1px solid #dde3e8;
}
