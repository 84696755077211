.root {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 19px;
  margin-top: 18px;
}

  .isReadContainer {
    width: 38px;
    margin-left: 13px;
  }

    .isReadIndicator {
      width: 10px;
      height: 10px;
      border: solid 1px #ffffff;
      border-radius: 50%;
      background-color: #4357eb;
    }

  .logoUrl {
    border-radius: 50%;
    max-width: 38px;
    max-height: 38px;
    margin-right: 13px;
   }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 28px;
  }

    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      margin: 0px;
      margin-bottom: 3px;
    }

    .description {
      text-align: left;
      font-size: 11px;
      line-height: 1.45;
      color: #868686;
      margin-bottom: 7px;
    }

    .projectDesc {
      margin-top: 5px;
      font-size: 9px;
    }

  .arrow {
    max-height: 6px;
    margin-right: 19px;
  }
