.root {
  margin: 34px 64px 0px 64px;
}

  .header {
    display: flex;
  }

    .headerLeft {
      flex: 1 1 auto;
    }

    .headerRight {
      flex: 0 0 auto;
    }

  .pageTitle {
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 16px;
  }

  .pageDescription {
    margin-bottom: 43px;
  }
