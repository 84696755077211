.root {
  margin-bottom: 37px;
  margin-top: 20px;
  height: 100%;
}

  .itemContainer {
    display: flex;
    flex-direction: column;
  }

    .addAnotherButton {
      display: flex;
      width: 177px;
      height: 33px;
      margin: auto;
      text-align: center;
      padding: 0px; 
      padding-left: 42px;
    }

  .emptyContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    border-radius: 5px; 
    background-color: #f9f9f9;
    padding: 43px 0px;
  }

    .emptyTitle {
      margin-top: 21px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }

    .emptyDesc {
      margin-top: 7px;
      font-family: MaisonNeue;
      font-size: 12px;
      line-height: 1.5;
      color: #a5a6a5;
      margin-bottom: 17px;
    }

    .addAnotherButtonEmpty {
      display: flex;
      width: 70px;
      height: 33px;
      margin: auto;
      text-align: center;
      padding: 0px; 
      padding-left: 20px;
    }



.pictureButtonBar {
  display: flex;
  align-content: center;
  border-top: solid 1px #e8e8e8; 
  padding: 23px 0px 0px 23px;
  background-color: #ffffff;
  width: 100%;
}

  .profileContainer {
    width: 35px;
    height: 35px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 14px;
  }

    .profilePic {
      display: inline;
      margin: 0 auto;
      max-height: 35px; 
      max-width: 35px; 
      object-fit: contain;
    }

  .pictureButtonPic {
    margin-left: 16px;
    height: 42px;
    padding: 0px 16px;
  }
