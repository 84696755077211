.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 575px;
  margin: 31px 34px 0px 34px;
}

  .content {
    margin-bottom: 25px;
  }

    .title {
      font-family: MaisonNeue;
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }

    .description {
      font-size: 12px;
      line-height: 1.58;
      color: #9b9b9b;
      margin-bottom: 24px;
    }

  .milestoneName {
    margin-top: 100px;
    font-size: 12px;
    color: #000000;
  }

  .formText {
    margin-top: 20px;
    font-size: 12px;
    color: #000000;
  }

  .textAreaContainer {
    border-radius: 5px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }

  .button {
    width: 100%;
  }

  .cancel { 
    position: absolute;
    top: 32px; 
    right: 32px;
  }
