.root {
  display: flex;
  flex-direction: column;
  margin: 20px 30px 0px 30px;
}

  .cancel {
    position: absolute;
    top: 28px;
    right: 28px; 
  }

  .title {
    color: black;
    font-size: 18px; 
    font-weight: 500px;
  }

  .desc {
    font-size: 12px; 
    line-height: 1.63;
    color: #868686;
    margin-bottom: 22px; 
  }

  .cancelButton {
    margin-top: 12px;
    text-align: center;
    height: 20px; 
    line-height: 1.58;
    font-size: 12px;
    color: #b8b8b8;
    margin-bottom: 5px; 
  }
