.root {
  display: flex;
  justify-content: space-between;
  padding: 21px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}

  .leftContent {
    display: flex;
  }

    .textContainer {
      display: flex;
      flex-direction: column;
    }

      .name {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 4px;
        margin-top: 0px;
      }

      .description {
        font-size: 12px;
        color: #7b7b7b;
      }

  .rightContent {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

    .date {
      text-align: right;
      width: 170px;
      margin-left: 12px;
      font-size: 12px;
      color: #868686;
    }
