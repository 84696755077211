.root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  overflow-y: hidden;
}
  /* Animations */
  .root.visible {
    transform: translateY(0);
  }
  .root.hidden {
    transform: translateY(100vh);
    transition: transform;
    transition-delay: 500ms;
  }

  .backdrop {
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.25);
  }
  /* Animations */
  .root.visible .backdrop {
    transition: opacity 250ms ease-in-out;
    opacity: 1;
    backdrop-filter: blur(4px);
  }
  .root.hidden .backdrop {
    opacity: 0;
    transition: opacity 250ms ease-in-out 250ms;
  }

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    border-radius: 6px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.25);
    border: 2px solid #e2e1e3;
    background-color: #fff;
    padding: 16px;
  }
  /* Animations */
  .root.visible .modal {
    opacity: 1.0;
    transform: translate(-50%, -50%);
    transition: opacity 250ms ease-in-out 200ms, transform 250ms ease-in-out 200ms;
  }
  .root.hidden .modal {
    opacity: 0.0;
    transform: translate(-50%, calc(-50% + 200px));
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  }

  .cancelButtonAnchor {
    position: relative;
    width: 100%;
  }

    .cancel { 
      position: absolute;
      top: 16px; 
      right: 16px; 
    }
