.root {
  display: flex;
  padding: 18px 26px 17px;
  height: 70px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
}

  .profilePicture {
    border-radius: 100%;
    width: 35px;
    height: 35px;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
  }

    .userInfo h4 {
      font-family: MaisonNeue, sans-serif;
      font-size: 14px;
      margin: 0 0 4px;
    }

    .userInfo span {
      font-family: MaisonNeue, sans-serif;
      font-size: 12px;
      color: #b8b8b8;
    }
