.root {
  height: 497px;  
  width: 540px; 
  font-size: 14px;
  display: flex; 
  flex-direction: column; 
  padding-top: 34px; 
  padding-left: 34px; 
}

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  .desc {
    font-size: 12px; 
    line-height: 1.58;
    color: #919191;
    margin-bottom: 31px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 480px;
  }

    .imageBar { 
      display: flex; 
      flex-direction: row;
      margin-bottom: 30px;
      align-items: center;
    }

      .clientLogo { 
        margin-right: 16px; 
      }

    .formText { 
      font-size: 12px;
      margin-bottom: 11px; 
    }

    .dropdownBar { 
      display: flex; 
      flex-direction: row; 
      height: 50px; 
      border-radius: 5px;
      border: solid 1px #e4e4e4;
      background-color: #ffffff;
      align-items: center;
      justify-content: space-between;
      padding-right: 25px;
      padding-left: 20px;
      width: 100%;
    }

.cancel { 
  position: absolute;
  top: 32px; 
  left: 524px; 
}
