.root {
  min-height: 376px; 
  box-shadow: 4px 4px 50px 0 rgba(0, 0, 0, 0.09);
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 36px 24px 0px 39px;
  border-radius: 8px; 
  position: relative;
  background-color: #ffffff;
}

  .cornerDiv {
    position: absolute;
    right: 24px;
    top: 21px;
    display: flex;
    align-items: center;
    z-index: 1;
  }

    .imageOptionContainer {
      padding: 0px;
      margin-right: 16px;
      position: relative;
      height: 38px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .imageOptionContainerPencil {
      margin-right: 16px;
      position: relative;
      height: 38px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6px;
    }

    .acceptButton {
      height: 38px;
      padding: 14px;
      width: 171px;
    }

    .disabledAcceptButton {
      text-align: center;
      padding: 0px 24px;
      padding-top: 12px;
      height: 38px;
      border-radius: 5px;
      border: solid 1px #e4e4e4;
      font-size: 14px;
      font-weight: 500;
      color: #cccdcc;
    }

    .releaseContainer {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #4d61f3;
      background-color: #4d61f3;
      width: 140px;
      height: 38px;
      border-radius: 5px;
    }

      .releaseText {
        color: white;
        font-weight: bold;
        font-size: 12px;
        font-weight: 500;
        user-select: none;
      }

    .dragContainer {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #000000;
      background-color: #ffffff;
      width: 140px;
      height: 38px;
      border-radius: 5px;
    }

      .dragImg {
        margin-right: 8px;
        user-select: none;
      }

      .dragText {
        color: black;
        font-weight: bold;
        font-size: 12px;
        font-weight: 500;
        user-select: none;
      }

  .textBox {
    display: flex;
    flex-direction: column;
    width: 385px; 
    margin-right: 55px;
  }

    .milestone {
      font-size: 11px;
      font-weight: bold;
      line-height: 2.09;
      letter-spacing: 0.79px;
      color: #4d61f3;
    }

    .milestoneTitle {
      font-size: 23px;
      font-weight: 500;
      color: #000000;
    }

    .milestoneDesc {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      color: #7b7b7b;
    }

  .infoBox {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

.completedMilestone {
  display: flex;
  padding: 36px 24px 0px 36px;
  background-color: white;
  height: 128px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  justify-content: space-between;
}

  .completedContent {
    display: flex;
    align-self: flex-end;
    align-items: center;
    flex-direction: column;
    margin-bottom: 21px;
  }

    .chevron {
      transform: scale(2);
      animation: pulse 3s ease-out infinite;
    }

    .completeText {
      margin-bottom: 6px;
      font-size: 12px;
      line-height: 1.92;
      color: #7b7b7b;
    }

  .completedImgContainer {
    align-self: center;
    height: 46px;
    width: 46px;
    margin-bottom: 36px;
    margin-left: 339px;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform: scale(2);
    }
    50% {
      opacity: .7;
      transform: scale(1.4);
    }
    100% {
      opacity: 1;
      transform: scale(2);
    }
  }
