.userSettings {
  -webkit-user-select: none;
  width: 283px;
  z-index: 2;
}

.userSettings a {
  text-decoration: none;
}

  .dropdown {
    width: inherit;
    position: absolute;
    top: 68px;
    background-color: white;
  }

    .dropdownLinkContainer {
      display: flex;
      align-items: center;
      width: inherit;
      border: solid 1px #ebebeb; 
      height: 69px;
    }

    .dropdownLink {
      padding-left: 20px; 
      color: black;
      font-size: 13px;
      font-weight: 500;
    }

  .logout {
    border: solid 1px #ebebeb;
    background-color: #4357eb;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
  }

    .logoutText {
      font-size: 13px;
      font-weight: bold;
      color: #ffffff;
    }