.root {
  margin: 34px 64px 0px 64px;
}

  .pageTitle {
    font-size: 26px;
    font-weight: 500;
    color: #000000;
    margin: 0 0 30px;
  }

  .sectionContainer {
    margin-bottom: 32px;
  }
