.root {
  padding: 14px;
  border-radius: 3px;
  background-color: #ffffff;
  width: 100%;
  border: none;
  font-size: 14px;
  resize: none;
}

.root::placeholder {
  color: #bbc7d0;
}
