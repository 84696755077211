.root {
  display: flex;
  flex-direction: column;
  margin: 31px 32px 0px 32px;
  width: 475px;
}

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    color: #000000;
  }

  .desc {
    margin-bottom: 23px;
    font-size: 12px;
    line-height: 1.58;
    color: #a5a6a5;
  }

  .button {
    margin-top: 27px;
    width: 100%;
  }
