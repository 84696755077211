.root {
  margin: 27px 39px;
}

  .boxes {
    display: flex;
  }

    .credentialBox {
      display: flex;
      flex-direction: column;
      min-width: 485px;
      border-radius: 8px;
      background-color: white;
      padding: 39px;
    }

      .upperBar {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 19px;
      }

      .boxTitle {
        margin: 0px;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
      }

      .formText {
        font-size: 12px;
        color: #000000;
        margin-bottom: 11px;
      }

    .informationBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      margin-right: 30px;
      margin-left:34px;
      background-color: white;
      padding: 39px;
    }

      .logoField {
        margin-top: 33px;
        margin-bottom: 35px;
      }

      .logoField img {
        border-radius: 50%;
        object-fit: cover;
      }

    .button {
      padding: 0px 20px;
      height: 38px;
    }
