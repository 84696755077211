.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px; 
  border-radius: 5px; 
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
}

  .infoText {
    margin-left: 28px;
    display: flex;
    flex-direction: column;
  }

  .attachmentName {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    margin: 0px; 
  }

  .attachmentFilePath {
    margin-top: 3px;
    font-size: 12px;
    line-height: 1.5;
    color: #919191;
  }

  .download {
    margin-right: 37px;
  }
