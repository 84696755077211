.root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

  .notifications {
    width: 64px;
    padding: 25px;
  }

    .notificationAnchor {
      position: relative;
    }

      .notificationBell {
        width: 100%;
        object-fit: fill;
      }

      .unreadNotification {
        position: absolute;
        background: #4357eb;
        border: solid 1px #ffffff;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 24px;
        right: 22px;
      }
