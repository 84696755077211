.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

  .innerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

    .logoContainer {
      position: relative;
    }

      .logo {
        display: block;
        object-fit: contain;
        max-width: 70px;
        max-height: 70px;
        margin-right: 16px;
      }

      .fileField {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 83px;
        cursor: pointer;
        opacity: 0;
      }

    .errorMessage {
      font-size: 13px;
      line-height: 1.85;
      color: #ff8c8c;
    }

    .label {
      font-size: 12px;
      color: #1e1c23;
    }
