.root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

  .root.hidden {
    transform: translateY(100vh);
  }
  .root.visible {
    transform: translateY(0);
  }

  .content {
    position: absolute;
    left: 50%;
    top: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
  }
    .root.hidden .content {
      opacity: 0;
    }
    .root.visible .content {
      opacity: 1;
      transition: opacity 200ms ease-in-out;
    }

    .loader {
      border: 8px solid #f0f0f0; /* Light grey */
      border-top: 8px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 80px;
      height: 80px;
      animation: spin 750ms ease-in-out infinite;
      margin-bottom: 32px;
    }

    .text {
      text-align: center;
      font-family: 'Roboto-Condensed';
      font-size: 24px;
      letter-spacing: 1px;
      white-space: normal;
    }

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
