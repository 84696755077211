.root {
  font-size: 14px;
  width: 306px;
  height: 253px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  padding: 75px;
  border: dashed 3px #e4e4e4;
}

.text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.newProjectLogo {
  padding: 0px 0px 22px 0px;
}

.description {
  margin-top: 7px;
  font-size: 12px;
  line-height: 1.5;
  color: #c5c6c5;
}
