.root {
  width: 100%;
    height: 100%;
}

  .previewImage {
    max-width: 128px;;
  }

  .customLabel {
    display: flex;
    flex-direction: column;
    border: 2px dashed #c5c6c5;
    border-radius: 5px;
    width: inherit;
    height: inherit;

    justify-content: center;
    align-items: center;
    padding: 0px 45px;
    margin-right: 29px;
    cursor: pointer;
    background: white;
  }
  .customLabel.isHovered {
    background: red;
  }

    .title {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }

    .description {
      margin-top: 8px;
      text-align: center;
      font-size: 12px;
      line-height: 1.5;
      color: #a5a6a5;
    }

  .fileInput {
    display: none;
  }
