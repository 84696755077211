.root {
  width: 496px; 
  height: 50px; 
  background: white; 
  border-radius: 5px; 
  border: solid 1px #e4e4e4;
  display: flex; 
  align-content: center;
}

  .searchBar {
    width: 100%;
    border: none;
    border-radius: 0px;
    border-left: solid 1px #e4e4e4;
  }

  .searchIcon {
    padding-left: 28px;
    padding-right: 28px; 
  }
