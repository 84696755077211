.root {
  width: 283px;
  display: flex;
  align-items: center;
  height: 67px;
  justify-content: space-between;
  border: solid 1px #ebebeb;
  border-bottom: none;
}

  .content {
    display: flex;
    align-items: center;
    margin-left: 19px; 
    height: 100%;
  }

    .imageContainer {
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

      .image{
        object-fit: fill;
        max-width: 100%;
        max-height: 100%;
      }

    .text {
      display: flex;
      flex-direction: column;
      margin: 15px 0px;
      margin-left: 21px;
    }

      .projectName {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
      }

      .projectType {
        margin-top: 3px;
        font-size: 11px;
        color: #c7c7c7;
      }

  .dropdown {
    margin: 0px 24px;
  }