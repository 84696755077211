.root {
  display: flex;
  flex-direction: column;
  margin: 20px 30px 0px 30px;
  position: relative;
  width: 540px;
}

  .cancel { 
    position: absolute;
    top: 0px; 
    right: 0px; 
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }

  .desc {
    margin-bottom: 23px;
    font-size: 12px;
    line-height: 1.58;
    color: #a5a6a5;
  }
