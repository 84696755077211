.root {
  position: fixed;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 333px;
  background: white;
  z-index: 5000;
  border-left: solid 1px #ebebeb;
  overflow-y: auto;
}

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 27px 20px 19px 20px;
    height: 101px;
    border-bottom: solid 1px #ebebeb;
  }

    .exitButton {
      max-width: 12px;
      max-height: 12px;
    }

    .textAndButtonContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

      .title {
        font-size: 16px;
        margin: 0px;
        font-weight: 500;
        color: #000000;
      }

      .markAllAsReadButton {
        font-size: 11px;
        color: #6277ff;
      }
    
  .notificationColumn {
    overflow-y: auto;
  }
