.root {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
  padding: 21px 28px;
}

  .content {
    font-size: 16px; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

    .title {
      font-size: 14px;
      font-weight: 500;
      margin: 0px;
    }

    .description {
      margin-top: 12px;
      max-width: 643px;
      font-size: 12px;
      line-height: 1.5;
      font-weight: normal;
      color: #868686;
    }

    .checkbox {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding-left: 60px;
    }

      .rejectedText {
        color: #4357eb;
        font-size: 14px;
        text-align: center;
      }

      .adminAction {
        align-self: flex-end;
        margin-top: 12px;
        border-radius: 5px;
        display: flex;
        width: 177px;
        height: 33px;
      }

        .acceptAction {
          width: 50%;
          background: #4357eb;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px 0px 0px 5px; 
          font-size: 14px;
        }

        .denyAction {
          width: 50%;
          background: #e4e4e4;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 5px 5px 0px;
          font-size: 14px;
        }
