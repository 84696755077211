.root {
  border: none;
  border-radius: 5px;  
  color: white;
  background-color: #4357eb;
  font-size: 14px;
  padding: 18px;
  font-family: MaisonNeue;
  cursor: pointer;
}
.root:disabled {
  opacity: 0.5;
  cursor: initial;
}

.noButton {
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}
