  .inputLabel {
    margin-bottom: 12px;
    font-size: 12px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 480px;
  }

  .forgotPassword {
    text-align: center;
    font-size: 14px;
    margin-top: 23px;
    color: #c0c0c0;
  }
