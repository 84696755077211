.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4357eb;
  border-radius: 8px;
  padding: 24px 24px 24px 47px;
}

  .infoBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    height: 55px; 
  }

    .infoText { 
      line-height: 2.09;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.79px;
    }

    .paymentText { 
      font-size: 23px;
      font-weight: 500;
    }

  .pendingMilestone {
    display: flex;
  }

    .dragContainer {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px white;
      background-color: #4357eb;
      width: 140px;
      height: 38px;
      margin-right: 14px;
      border-radius: 5px;
    }

      .dragImg {
        margin-right: 8px;
        user-select: none;
      }

      .dragText {
        color: white;
        font-weight: bold;
        font-size: 12px;
        font-weight: 500;
        user-select: none;
      }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 192px;
    height: 38px;
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 14px;
    color: #576cfa;
    font-weight: 500;
  }
