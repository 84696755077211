.root {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

  .logoutButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    border: solid 1px #ebebeb;
    background-color: #4357eb;
    -webkit-user-select: none;
  }

    .logoutText {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.79px;
      color: #ffffff;
    }