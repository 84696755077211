.root {
  font-size: 14px;
  width: 306px;
  height: 253px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: white;
  border-radius: 5px;
  padding: 21px 22px;
  box-shadow: 4px 4px 50px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e4e4e4;
}

  .bottombar {
    margin-top: 19px; 
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    
    .projectDesc {
      font-size: 12px;
      color: #a5a6a5;
      line-height: 1.5;
    }

  .logo {
    margin: auto 0px;
    object-fit: contain;
    object-position: center;
    height: 120px;
  }
