
.logs {
  padding-bottom: 14px;
}

  .logContainer {
    margin-top: 14px;
  }

.newLogContainer {
  width: 100%;
  display: flex;
  height: 97px;
  padding: 22px 0px 22px 22px;
  border-top: 1px solid #e8e8e8;
}

  .inputField {
    flex: 1 0;
    border: none;
    margin-right: 14px;
  }

  .button {
    height: 38px;
    width: 42px;
  }


.emptyRoot {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  border-radius: 5px; 
  background-color: #f9f9f9;
  padding: 43px 0px;
}

  .emptyTitle {
    margin-top: 21px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }

  .emptyDesc {
    margin-top: 7px;
    font-family: MaisonNeue;
    font-size: 12px;
    line-height: 1.5;
    color: #a5a6a5;
    margin-bottom: 17px;
  }
