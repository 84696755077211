.root {
  width: 100%;
  border-collapse: collapse;
}

  .tableHeader {
    background-color: #fff;
  }

    .tableHeader tr th {
      font-family: 'MaisonNeue-Book', sans-serif;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      padding: 23px 25px;
      color: rgb(183, 184, 183);
      border: solid 1px rgb(228, 228, 228);
    }

  .tableBody {
    background-color: #fff;
  }

    .tableBody tr:nth-child(even) {
      background-color: rgb(240, 244, 251);
    }

    .tableBody tr td {
      font-family: 'MaisonNeue-Book', sans-serif;
      font-size: 12px;
      padding: 23px 25px;
      border: solid 1px rgb(228, 228, 228);
    }

.editButton {
  font-family: MaisonNeue-Bold, sans-serif;
  color: rgb(67, 87, 235);
  margin-right: 15px;
}

.deleteButton {
  font-family: MaisonNeue-Bold, sans-serif;
  color: rgb(239, 74, 73);
}
