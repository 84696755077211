
.root {
  display: flex;
  min-height: 376px;
}
  .detail {
    flex: 0 0 400px;
    margin-top: 36px;
    margin-left: 39px;
    margin-right: 73px;
  }

    .title {
      color: #000;
      font-family: 'MaisonNeue-Medium', sans-serif;
      font-size: 23px;
      font-weight: normal;
      margin-top: 4px;
    }

    .headline {
      font-family: MaisonNeue, sans-serif;
      font-size: 11px;
      font-weight: bold;
      line-height: 2.09;
      letter-spacing: 0.79px;
      color: #6277ff;
      text-transform: uppercase;
    }

  .content {
    flex: 1 1 auto;
    padding-top: 40px;
    padding-right: 24px;
    position: relative;
  }
