.root {
  display: flex;
  flex-direction: column;
  margin: 31px 32px 0px 32px;
  width: 475px;
}

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }

  .desc {
    margin-bottom: 23px;
    font-size: 12px;
    line-height: 1.58;
    color: #a5a6a5;
  }

  .formText {
    font-size: 12px;
    color: #000000;
  }

  .textAreaContainer {
    border-radius: 5px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
  }

  .button {
    width: 100%;
  }

  .cancel { 
    position: absolute;
    top: 32px; 
    right: 32px; 
  }
