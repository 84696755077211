.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 480px;
}

.disabledButton {
  background: #9b9b9b;
  cursor: default;
}
