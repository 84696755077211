.root {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
}

  .spacer {
    flex: 1;
    flex: 0 0 300px;
  }

  .formContainer {
    position: relative;
    width: 1px;
    flex: 0 0 0px;
  }

  .errorBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    height: 350px;
    width: 480px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 24px 24px 37px 24px;
    box-shadow: 4px 4px 50px 0 rgba(0, 0, 0, 0.09);
  }

    .title {
      font-size: 23px;
      font-weight: 500;
      margin-top: 32px;
    }

    .description {
      max-width: 394px;
      color: #9b9b9b;
    }

  .backgroundImage {
    flex: 1 0;
    max-height: 100vh;
  }

  .logo {
    position: absolute;
    top: 20px;
    right: 36px;
    width: 89px;
  }

.contentFormContainer {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 24px 24px 37px 24px;
  box-shadow: 4px 4px 50px 0 rgba(0, 0, 0, 0.09);
}

  .contentFormTitle {
    font-size: 23px;
    font-weight: 500;
    margin-top: 32px;
  }

  .contentFormDescription {
    max-width: 394px;
    margin-bottom: 25px;
    color: #c0c0c0;
    font-size: 14px;
    line-height: 1.64;
  }
