.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 306px;
  height: 253px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
  padding: 22px;
}

  .imageContainer {
    align-self: center;
    margin-top: 22px;
    height: 120px;
    width: 120px;
  }

    .image {
      margin: auto 0px;
      object-fit: contain;
      object-position: center;
      max-width: 90%;
      max-height: 90%;
    }

  .bottomBar {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }

    .text {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      max-width: 216px;
      overflow: hidden;
      flex: 1;
    }

      .fileName {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 4px;
      }

      .fullFileName {
        font-size: 12px;
        line-height: 1.5;
        color: #919191;
        max-width: 216px;
        word-wrap: break-word;
      }
  
    .button {
      cursor: pointer;
    }
