.root {}

  .addAccessButton {
    position: absolute;
    padding: 9px 14px;
    height: 33px;
    top: 24px;
    right: 24px;
  }

  .accessUsers {
    margin-top: 20px;
  }
