.root {
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  user-select: none;
}

  .infoBar {
    display: flex;
    flex-direction: row;
  }

    .barAnchor {
      position: relative;
      height: 100%;
    }

      .tabButton {
        margin-right: 31px;
        letter-spacing: 0.79px;
        color: #7b7b7b;
        font-size: 11px;
        padding-bottom: 23px;
      }

        .bold {
          font-weight: bold;
        }

      .activeBar {
        position: absolute;
        top: 34px;
        width: 30px; 
        height: 1px;
        background: #4357eb;
        z-index: 1;
      }
