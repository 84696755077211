.linkName {
  margin-top: 100px;
  font-size: 12px;
  color: #000000;
}

.formText {
  margin-top: 20px;
  font-size: 12px;
  color: #000000;
}

.textAreaContainer {
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #ffffff;
}

.button {
  width: 100%;
}
