body {
  margin: 0;
  padding: 0;
  font-family: 'MaisonNeue', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f8ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

p {
  line-height: 1.64;
  color: #919191;
  font-size: 14px;
}



@font-face {
  font-family: "MaisonNeue";
  font-weight: normal;
  font-style: normal;
  src: url("assets/fonts/MaisonNeue/MaisonNeue-Book.ttf") format("truetype");
}
@font-face {
  font-family: "MaisonNeue";
  font-weight: normal;
  font-style: italic;
  src: url("assets/fonts/MaisonNeue/MaisonNeue-BookItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MaisonNeue";
  font-weight: bold;
  font-style: normal;
  src: url("assets/fonts/MaisonNeue/MaisonNeue-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MaisonNeue";
  font-weight: bold;
  font-style: italic;
  src: url("assets/fonts/MaisonNeue/MaisonNeue-BoldItalic.ttf") format("truetype");
}

