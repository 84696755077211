.content {
  padding: 45px 29px 40px 64px;
}

  .topBar { 
    display: flex;
    align-items: center;
    margin-right: 48px;
    margin-bottom: 44px;
  }

    .uploadAttachmentButton {
      margin-right: auto;
    }

    .searchBar {
      width: 100%;
      border: none;
      border-radius: 0px;
      border-left: solid 1px #e4e4e4;
    }

    .pageTitle {
      font-weight: normal;
      font-size: 26px;
      margin: 0 16px 0 0;
    }

  .search {
    width: 496px; 
    height: 50px; 
    background: white; 
    border-radius: 5px; 
    border: solid 1px #e4e4e4;
    display: flex; 
    align-content: center;
  }

    .searchIcon {
      padding-left: 28px;
      padding-right: 28px; 
    }

  .attachmentContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
 
    .attachmentCardContainer {
      margin-right: 29px;
      margin-bottom: 29px;
    }
