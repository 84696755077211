.root {}

  .formText {
    font-size: 12px;
    color: #000000;
    margin-bottom: 11px;
  }

  .dropdownBar {
    display: flex;
    flex-direction: row;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #e4e4e4;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
    padding-left: 20px;
    width: 100%;
  }

  .button {
    height: 35px;
    padding: 12px 18px;
  }

  .logoFieldContainer {
    margin-bottom: 27px;
  }
