.content {
  padding: 45px 29px 40px 64px;
}

  .topBar { 
    display: flex;
    justify-content: space-between;
    padding-right: 33px; 
  }

    .pageTitle {
      font-weight: 500;
      font-size: 26px;
      padding-bottom: 44px;
    }

  .pageDescription { 
    padding-bottom: 40px; 
  }

  

  .projects {
    display: flex;
    flex-wrap: wrap;
  }

    .projectCardContainer {
      margin-right: 29px;
      margin-bottom: 29px;
    }

