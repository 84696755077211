.root {
  display: flex;
  background-color: white;
  border: 1px solid #ebebeb;
  height: 69px;
}

  .logoContainer {
    flex: 0 0 89px;
    margin: 20px 100px 20px 28px;
  }

    .logoContainer img {
      max-width: 100%;
      max-height: 100%;
    }

  .linksContainer {
    display: flex; 
    align-items: center;
  }

    .navLinkContainer {
      position: relative;
      margin-right: 40px; 
    }

      .link {
        white-space: nowrap;
        font-size: 13px;
        font-weight: normal;
        color: #9b9b9b;
        padding: 27px 0px;
        text-decoration: none;
      }

      .activeLink {
        font-weight: bold;
        color: black;
        border-bottom: 1px solid blue;
        z-index: 1;
      }

      .highlightBar {
        position: absolute;
        top: 41px;
        width: 100%;
        height: 1px;
        background: #4357eb;
        z-index: 1;
      }

  .content {
    width: 100%;
  }
